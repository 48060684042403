import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,
  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import HeroMobile from "./../../images/Menopauzal-mobile.jpg"
import HeroTablet from "./../../images/Menopauzal-tablet.jpg"
import HeroDesktop from "./../../images/Menopauzal-desktop.jpg"
import HeroXL from "./../../images/Menopauzal-desktop-xl.jpg"
import Feature1 from "./../../images/menopauzal-feature.jpg"
import Feature4 from "./../../images/prost-psor-plus-herbal-guarantee.jpg"

const MenopauzalPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "MenopauzalEn" }}>
    <Seo title="Menopauzal" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>MENOPAUZAL</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={HeroXL} media="(min-width: 1200px)" />
            <source srcSet={HeroDesktop} media="(min-width: 992px)" />
            <source srcSet={HeroTablet} media="(min-width: 767px)" />
            <source srcSet={HeroMobile} media="(min-width: 370px)" />
            <img
              srcSet={HeroMobile}
              alt="Menopauzal"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Menopauzal
          </h5>
          <h1 className="display-4 font-light">For menopause</h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">Menopauzal</h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            For menopause
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            A herbal bouquet for the symptoms of menopause.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/ena/menopauzal-at-climax"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Buy online at <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Available in pharmacies across the country</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>solvent free</li>
                <li>preservatives free</li>
                <li>gluten free</li>
                <li>sugar free</li>
                <li>GMO-free</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Menopause should not be a burden</h2>
        </Col>
      </Row>
      <Row className="product-container mb-4">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Menopause should not be a burden"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Menopauzal supports the functions of the female reproductive system
            during menopause. Yarrow is known as a "female herb" because it
            helps relieve menopause, uterine bleeding, painful and heavy
            menstruation. Water pepper leaves help regulate irregular
            menstruation. Menopause promotes good self-esteem and mood in women.
          </p>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Guarantee for quality and safety</h2>
        </Col>
      </Row>
      <Row className="product-container mb-4">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Guarantee for quality and safety"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Standardized plant extracts are obtained from plants or parts
            thereof by extraction of biologically active components. Their
            advantage is that the type of active substances can be controlled
            and maintained in a certain quantity and quality.
          </p>
          <p>
            Taking an extract with a proven effect ensures optimal impact on
            body functions. The plant extracts included in Menopauzal complement
            each other's beneficial effects.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Additional information</h5>
        </Col>
      </Row>
      <Row className="mb-4 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Contents
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Yarrow stalks</strong> (Herba Millefolii) 59,4 mg
                  </p>
                  <p>
                    <strong>Water pepper stalks</strong> (Herba Polygonum
                    hydropiper) 39,6 mg
                  </p>
                  <p>
                    <strong>Raspberry leaves</strong> (Folia Rubi idaei) 39,6 mg
                  </p>
                  <p>
                    <strong>Chickpeas stalks</strong> (Herba Teucrii chamaedris)
                    39,6 mg
                  </p>
                  <p>
                    <strong>Thyme stalks</strong> (Herba Serpilli) 33,0 mg
                  </p>
                  <p>
                    <strong>Chamomile flower</strong> (Flores Chamomillae) 33,0
                    mg
                  </p>
                  <p>
                    <strong>Hop cones</strong> (Strobuli lupuli) 29,7 mg
                  </p>
                  <p>
                    <strong>Basil stalks</strong> (Herba Basilici) 29,7 mg
                  </p>
                  <p>
                    <strong>Walnut leaves</strong> (Folia Juglandis) 26,4 mg
                  </p>
                  <p>Excipients: maltodextrin, magnesium stearate</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Intake
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>2 times daily, 1-2 tablets after meals</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Contraindications
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    None identified. Do not exceed the recommended daily dose.
                    Do not use the product as a substitute for a varied diet.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Effects
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Menopauzal is a natural product that supports the functions
                    of the female reproductive system during menopause.
                    Contributes to improving sexual function in women. In
                    addition to diet, it promotes good self-esteem and mood in
                    women.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default MenopauzalPageEn